.mainWrapper {
  height: 100%;
  padding-top: 60px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.spinner {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinnerText {
  margin-top: 15px;
  font-size: 1.2em;
  color: #888;
}

@media (min-width: 720px) {
  .mainWrapper {
    margin-left: 220px;
  }
}
